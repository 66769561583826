import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Browser from "../../../components/BrowserDemo";
import Lesson from "../../../components/Lesson";
export const meta = {
  published: false,
  publishedAt: "2019-12-01",
  title: "Why We Open Sourced Our Product",
  summary: "THIS IS MISSING" // image: "/static/images/css-in-js.png"

};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`👋 I am Max, the technical co-founder of `}<a parentName="p" {...{
        "href": "https://spectrum.chat"
      }}>{`Spectrum`}</a>{`. Spectrum is a chat app for large online communities which GitHub acquired a year ago. We are a team of three with a predominantly frontend and design background and worked on it for three years.`}</p>
    <p>{`Spectrum was a standard closed source product until 3rd of April, 2018, when we announced that we had `}<a parentName="p" {...{
        "href": "https://spectrum.chat/spectrum/general/announcement-spectrum-goes-open-source~556b4915-7269-46a7-96e6-f38446d14146"
      }}>{`open sourced the entire codebase`}</a>{`. Why did we decide to open source our entire product?`}</p>
    <h2>{`The Benefits`}</h2>
    <p>{`The two big reasons for open sourcing our product were feeling good and marketing. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      